@import "font-awesome/font-awesome";

// DUMMY-Klassen
//
// .papy
// papy-bind-add-artikel
//   Update der Warenkorb-Anzeige
//   (siehe main.js)
//
// .papy-bind-tooltip
//   Toggle Tooltip
//   (siehe main.js)
//
// .papy-internal-link
//   für Sprünge innerhalb einer Seite mit JavaScript (scrollIntoView)
//   dadurch wird vermieden, dass die Browser-History verändert wird
//   Sprungziel ist das Attribut "bring-into-view"
//   (siehe main.js)


// Variablen
// Farben
$orange: #e0a700;
$orange-2: mix(white, $orange, 30%);
$orange-3: mix(white, $orange, 75%);
$orange-4: mix(white, $orange, 90%);
$papy-dark: #555;
$papy-light: #eee;
$papy-gray: #696969;

$papy-darken-fact: 0.05;
$papy-darken2-fact: 0.1;
$papy-lighten: rgba(255,255,255,0.1);
$papy-darken: rgba(0, 0, 0, $papy-darken-fact);
$papy-darken2: rgba(0, 0, 0, $papy-darken2-fact);
$papy-darken3: rgba(0, 0, 0, 0.15);
$papy-gradient-orange: $orange linear-gradient(175deg, $orange-2, $orange) repeat-x !important;
// sonstige Variablen
$papy-body-max-width: 1280px;
$papy-zindex-max: $zindex-dropdown - 1;

// für alle breakpoints verfübar:
//  max-width:20em
//  width: auto
@each $breakpoint in map-keys($grid-breakpoints) {
  @include media-breakpoint-up($breakpoint) {
    $infix: breakpoint-infix($breakpoint, $grid-breakpoints);
    .papy-mw#{$infix}-20-em   { max-width: 20em; }
    .papy-w#{$infix}-auto   { width: auto !important; }
  }
}

.papy-wrap {
    white-space: normal !important;
}
.papy-nowrap {
    white-space: nowrap !important;
}

.hyphens-none{
    hyphens:none !important;
}

.papy-bg-light {
    background-color: $papy-light !important;
    color: black;
}
.papy-bg-dark {
    background-color: $papy-dark !important;
    color: #fff;
}
.papy-bg-darken {
    background-color: $papy-darken !important;
}
.papy-bg-black {
    background-color: $black !important;
}
.papy-bg-yellow {
    background-color: #ffff00;
}
.papy-bg-orange {
    background-color: #ff6801;
}
.papy-bg-logo-orange {
    background-color: #ff9751;
}
.papy-bg-gold {
    background-color: #cda958;
}
.papy-bg-green {
    background-color: #7cc600;
}
.papy-bg-gray-500 {
    background-color: #adb5bd;
}
.papy-bg-pink {
    background-color: #ff6cb1;
}
.papy-bg-pastellblau {
    background-color: #c9e3f8;
}
.papy-bg-pastellgelb {
    background-color: #f4fa58;
}
.papy-bg-pastelllila {
    background-color: #d6d3ea;
}
.papy-bg-pastellrosa {
    background-color: #f8ccdb;
}
.papy-bg-pastellrot {
    background-color: #e63f53;
}
.papy-bg-blau-marine {
    background-color: #002754;
}
.papy-bg-blau-hell {
    background-color: #4ab7e5;
}
.papy-bg-blau-dunkel {
    background-color: #0404b4;
}
.papy-bg-mintgruen {
    background-color: #3eb6aa;
}
.papy-bg-gruen-fluor {
    background-color: #39ff14;
}
.papy-bg-rot-wein {
    background-color: #ac0f13;
}
.papy-bg-rosa {
    background-color: #f7cadf;
}
.papy-bg-rot-kariert {
    background-image: url('/static/img/bg-bilder/rot-kariert.jpg');
}
.papy-bg-gold-muster {
    background-image: url('/static/img/bg-bilder/gold-muster.jpg');
}
.papy-bg-transparenz {
    background-image: url('/static/img/bg-bilder/transparenz.png');
}
.papy-bg-silber-metallic {
    background-image: url('/static/img/bg-bilder/silber-metallic.jpg');
}
.papy-bg-textilband-silber {
    background-image: url('/static/img/bg-bilder/textilband-silber.jpg');
}
.papy-bg-silber-matt {
    background-image: url('/static/img/bg-bilder/silber-matt.jpg');
}
.papy-bg-gold-metallic {
    background-image: url('/static/img/bg-bilder/gold-metallic.jpg');
}
.papy-bg-tze-mpsl31 {
    background-image: url('/static/img/bg-bilder/tze-mpsl31.jpg');
}
.papy-bg-rosa-herzen {
    background-image: url('/static/img/bg-bilder/rosa-herzen.jpg');
}
.papy-bg-tze-pr935 {
    background-image: url('/static/img/bg-bilder/tze-pr935.jpg');
}
.papy-bg-tze-pr831 {
    background-image: url('/static/img/bg-bilder/tze-pr831.jpg');
}
.papy-bg-recycling-600x842px {
    background-color: #e7e6d5;
}
.brady-blue {
    color: #014991;
}
.papy-text-black {
    color: $black !important; //
}
.papy-text-gold {
    color: #cda958;
}
.papy-text-blue {
    color: #2a47db;
}
.papy-text-red {
    color: #e90909;
}
.papy-text-gray-500 {
    color: #adb5bd;
}
.table .papy-thead-dark th {
    @extend .papy-bg-dark;
    border-color: #32383e;
}
.scrolling table {
    table-layout: inherit;
 *margin-left: -100px;/*ie7*/
}
.scrolling td, th {
    vertical-align: center;
	min-width: 160px;
}
.scrolling th {
	position: absolute;
 *position: relative; /*ie7*/
	left: 0;
	width: 100px;
}
.outer {
	position: relative
}
.inner {
	overflow-x: auto;
	overflow-y: visible;
	margin-left: 160px;
}
.inner-left {
	@extend .inner;
	overflow-x: auto;
	overflow-y: visible;
	margin-left: 1rem;
}

.width-80px { width: 80px; }
.width-90px { width: 90px; }
.width-100px { width: 100px; }
.width-120px { width: 120px; }
.width-140px { width: 140px; }
.width-160px { width: 160px; }
.width-180px { width: 180px; }
.width-200px { width: 200px; }
.width-220px { width: 220px; }
.width-240px { width: 240px; }
.width-260px { width: 260px; }

.height-60px { height: 60px; }
.height-80px { height: 80px; }

.width-10em { width: 10em; }
.width-12em { width: 12em; }
.width-15em { width: 15em; }

.max-width-20em { max-width: 20em; }

.papy-card {
    @extend .card;
    .card-header {
	@extend .card-header;
	@extend .d-flex;
	@extend .flex-row;
	@extend .align-items-center;
	font-weight: bold;
	font-size: 1.25rem;
    }
    .card-header-small {
	@extend .card-header;
	@extend .d-flex;
	@extend .flex-row;
	@extend .align-items-center;
	font-weight: bold;
	font-size: 1rem;
	padding: 0.25rem 0.75rem;
    }
    .card-header-big {
	@extend .card-header;
	@extend .d-flex;
	@extend .flex-row;
	font-weight: bold;
	font-size: 2rem;
    }
    .card-title {
	@extend .mb-3;
	font-weight: bold;
	font-size: 1.25rem;
    }
    .card-header, .card-footer {
	@extend .papy-bg-light;
    }
    ul {
	@extend .pl-4;
    }
}

.papy-folderimage {
    @extend .float-right;
    @extend .ml-4;
    width: 40%;
    height:auto;
    max-width: 176px;
    max-height: 176px;
}

.papy-imagecard {
    @extend .papy-card;
    width: 190px;
    .card-body {
	@extend .card-body;
	@extend .p-1;
	padding-top: 0px !important;
	font-size: 0.8rem;
	font-weight: bold;
	text-align: center;
	height: 3em;
	overflow-y: auto;
    }
    .card-img-top {
	@extend .card-img-top;
	@extend .ml-auto;
	@extend .mr-auto;
	width: 80px;
	height:auto;
	display: block;
	vertical-align: middle;
    }
    .img-top {
	height: 80px;
    }
}

.papy-techdata-show {
    @extend .btn;
    @extend .dropdown-toggle;
    @extend .show;
    font-weight: bold;
    margin-right: 4px;
    margin-bottom: 4px;
}

/* .papy-techdata-collapse { */
/*     @extend .btn; */
/*     @extend .dropdown-toggle; */
/*     @extend .collapse; */
/*     font-weight: bold; */
/*     margin-right: 4px; */
/*     margin-bottom: 4px; */
/* } */

/* .papy-techdata-open-all { */
/*     @extend .btn; */
/*     @extend .bg-light; */
/*     @extend .dropdown-toggle; */
/*     font-weight: bold; */
/*     margin-right: 4px; */
/*     margin-bottom: 4px; */
/* } */

.papy-link-top {
    @extend .ml-auto;
    span:first-child {
	@extend .fa;
	@extend .fa-arrow-up;
	&::before {
	    padding-right:4px;
	}
    }
}


// Tooltip, der durch Klicken geöffnet und geschlossen wird. Beispiel:
// <div class="papy-info" data-toggle="tooltip" data-trigger="click" data-placement="auto" title="HUHU"></div>
.papy-info {
    @extend .fa;
    @extend .fa-info-circle;
    @extend .ml-2;
    @extend .d-inline-block;
    color: blue;
    cursor: default;
}
.papy-info-intern {
    @extend .papy-info;
    color: red !important;
    @extend .mx-1;
    cursor:  pointer;
}

// Allgemein

html {
    font-size: 90%;
    hyphens:auto;
}

body {
    background-color: white;
}

.papy-body {
    @extend .mx-auto;
    background-color: $papy-darken3;
    max-width: $papy-body-max-width;
}

.papy-navbar {
    @extend .navbar;
    @extend .papy-bg-light;
    @extend .px-1;
}

.papy-infopage-navbar-folder {
    @extend .navbar;
    @extend .bg-light;
    align-items: top;
    padding: 0rem;
    margin-top: 0rem;
    margin-bottom: 0rem;
    border: 0px;
    padding-left: 0rem !important;
    ul {
	padding-left: 0rem !important;
	}
    table {
	@extend .align-top;
	td {
	    @extend .align-top;
	}
    }
}

.papy-text-left {
    text-align: left !important;
}
.papy-text-right {
    text-align: right !important;
}

.papy-max-size-100-100 {
    max-width: 100px;
    max-height: 100px;
}
.papy-max-size-50-50 {
    max-width: 50px;
    max-height: 50px;
}
.papy-max-size-40-40 {
    max-width: 40px;
    max-height: 40px;
}
.papy-max-size-30-30 {
    max-width: 30px;
    max-height: 30px;
}
.papy-max-size-25-25 {
    max-width: 25px;
    max-height: 25px;
}

.papy-max-size-3ex {
    max-width: 3ex;
    max-height:3ex;
}

.papy-border-1 {
    border-style: solid !important;
}
.papy-border-top-1 {
    border-top-style: solid !important;
}
.papy-border-bottom-1 {
    border-bottom-style: solid !important;
}
.papy-border-left-1 {
    border-left-style: solid !important;
}
.papy-border-right-1 {
    border-right-style: solid !important;
}

.papy-border-width-1 {
    border-width: 1px !important;
}
.papy-border-width-2 {
    border-width: 2px !important;
}
.papy-border-width-3 {
    border-width: 3px !important;
}

.papy-font-size-75-proz {
    font-size: 75%;
}
.papy-font-size-90-proz {
    font-size: 90%;
}
.papy-font-size-110-proz {
    font-size: 110%;
}
.papy-font-size-120-proz {
    font-size: 120%;
}
.papy-font-size-150-proz {
    font-size: 150%;
}
.papy-font-size-200-proz {
    font-size: 200%;
}

h1 {
    font-weight: bold;
    font-size: 2.0rem;
    @include media-breakpoint-up(sm) {
	font-size: 2.25rem;
    }
    @include media-breakpoint-up(md) {
	font-size: 2.5rem;
    }
}

h2 {
    font-weight: bold;
    font-size: 1.5rem;
    @include media-breakpoint-up(sm) {
	font-size: 1.75rem;
    }
    @include media-breakpoint-up(md) {
	font-size: 2rem;
    }
}

h3 {
    font-weight: bold;
    font-size: 1.35rem;
    @include media-breakpoint-up(sm) {
	font-size: 1.5rem;
    }
    @include media-breakpoint-up(md) {
	font-size: 1.75rem;
    }
}

h4 {
    font-weight: bold;
    font-size: 1.25rem;
    @include media-breakpoint-up(sm) {
	font-size: 1.30rem;
    }
    @include media-breakpoint-up(md) {
	font-size: 1.5rem;
    }
}

h5 {
    font-weight: bold;
    font-size: 1.15rem;
    @include media-breakpoint-up(sm) {
	font-size: 1.20rem;
    }
    @include media-breakpoint-up(md) {
	font-size: 1.25rem;
    }
}

.h1 {
    font-weight: bold;
    font-size: 2.0rem;
    @include media-breakpoint-up(sm) {
	font-size: 2.25rem;
    }
    @include media-breakpoint-up(md) {
	font-size: 2.5rem;
    }
}

.h2 {
    font-weight: bold;
    font-size: 1.5rem;
    @include media-breakpoint-up(sm) {
	font-size: 1.75rem;
    }
    @include media-breakpoint-up(md) {
	font-size: 2rem;
    }
}

.h3 {
    font-weight: bold;
    font-size: 1.35rem;
    @include media-breakpoint-up(sm) {
	font-size: 1.5rem;
    }
    @include media-breakpoint-up(md) {
	font-size: 1.75rem;
    }
}

.h4 {
    font-weight: bold;
    font-size: 1.25rem;
    @include media-breakpoint-up(sm) {
	font-size: 1.30rem;
    }
    @include media-breakpoint-up(md) {
	font-size: 1.5rem;
    }
}

.h5 {
    font-weight: bold;
    font-size: 1.15rem;
    @include media-breakpoint-up(sm) {
	font-size: 1.20rem;
    }
    @include media-breakpoint-up(md) {
	font-size: 1.25rem;
    }
}

.papy-mb--04rem {
    margin-bottom: -0.4rem;
}

.papy-download-pdf {
	@extend .fa;
	@extend .fa-file-pdf-o;
	&::before {
	    padding-right:4px;
	}
}
.papy-dropdown-icon-before {
	@extend .fa;
	@extend .fa-angle-down;
	&::before {
	    padding-right:4px;
	}
}
.papy-info-icon-before {
	@extend .fa;
	@extend .fa-info-circle;
	&::before {
	font-size: 150%;
	color: $orange;
	    padding-right:4px;
	}
}
.papy-info-icon-before-100 {
	@extend .fa;
	@extend .fa-info-circle;
	&::before {
	color: $orange;
	    padding-right:4px;
	}
}
.papy-info-icon-before-sm {
	@extend .fa;
	@extend .fa-info-circle;
	&::before {
	font-size: 80%;
	color: $orange;
	    padding-right:2px;
	}
}
.papy-exclamation-icon-before {
	@extend .fa;
	@extend .fa-exclamation-circle;
	font-size: 150%;
	color: #FF0000;
	&::before {
	    padding-right:4px;
	}
}

// kleiner Zeichenabstand z. B. für pagination.first (<<)
.papy-text-space-tight {
    letter-spacing: -0.3em;
}

// folderuebersicht.pt

.papy-folder-hover-highlight {
    color: black;

    &:hover, &:focus {
		 outline-style: None;
		 color: black;
		 > div {
		     border-color: black;
		 }
	     }
}

// facetlist.pt

span, div {
    &.papy-aktion-facetlist-cart {
	@extend .text-nowrap;
	&::before {
	    @extend .d-inline-block;
	    position:relative;
	    top:-12px;
	    left:18px;
	    vertical-align: top;
	    z-index: $papy-zindex-max;
	    content: url(/static/img/prozent_15px.png);
	    width:0px;
	    margin-left: -8px;
	}
    }
    &.papy-aktion-facetlist-img {
	@extend .text-nowrap;
	&::before {
	    @extend .d-inline-block;
	    position:relative;
	    vertical-align: top;
	    z-index: $papy-zindex-max;
	    content: url(/static/img/prozent_30px.png);
	    width:0px;
	}
	img {
	    position:relative;
	    z-index: 10;
	}
    }
    &.papy-aktion-facetlist-img-small {
	@extend .text-nowrap;
	&::before {
	    @extend .d-inline-block;
	    position:relative;
	    vertical-align: top;
	    z-index: $papy-zindex-max;
	    content: url(/static/img/prozent_20px.png);
	    width:0px;
	}
	img {
	    position:relative;
	    z-index: 10;
	}
    }
}

.papy-verfuegbar {
  padding-left:3Px;
  padding-right:3Px;
  white-space:nowrap;
}
.papy-verf_rot {
  @extend .papy-verfuegbar;
  background-color:#FFA0A0;
}
.papy-verf_gelb {
  @extend .papy-verfuegbar;
  background-color:#FFFF88;
}
.papy-verf_gruen {
  @extend .papy-verfuegbar;
  background-color:#A0FFA0;
}

.papy-facetlist-hover-highlight{
    &:hover {
	border-color: black;
	color: black;
	text-decoration: none;
    }
    * {
	color: black;
	text-decoration: none;
	cursor: default;
    }
    &:hover * {
	color: black;
	text-decoration: none;
    }
    a:focus {
	outline-style: None;
    }
}
.papy-facetlist-cart-button {
    @extend .px-2;
    font-size: 28px;
    background: $papy-gradient-orange;
    &:hover {
	background-color: #ddd;
	box-shadow: 1px 1px 1px 1px #ddd;
	border-radius: 2px;
	cursor: pointer;
    }
    *:hover {
	cursor: pointer;
    }
}

// bigbox

#einzelpreis {
    font-size: 110%;
    font-weight:bold;
}
.papy-bigbox-cart-button {
    @extend .btn;
    @extend .btn-lg;
    @extend .align-items-center;
    color: #444;
    font-weight: bold;
    cursor: pointer;
    width: 100%;
    background: $papy-gradient-orange;
    div:only-child {
	@extend .d-flex;
	@extend .justify-content-center;
	div:last-child {
	    margin-left:8px;
	}
    }
}
.papy-bigbox-cart-image {
    @extend .fa;
    @extend .fa-shopping-cart;
    @extend .text-white;
    padding: 0px;
    font-size: 28px;
    &::before {
	padding-right:8px;
    }
}

// option_s.pt

.papy-filter-dropdown-menu {
    max-height:20em;
    overflow:auto;
}

// versandkosten

#versandTabs {
    li {
	background-color: $papy-darken;
    }
    a {
	@extend .p-1;
	@extend .px-sm-3;
	@extend .py-sm-2;
	color: #555;
	white-space: wrap;
	&:focus {
	    outline-style: None;
	}
	&.active {
	    @extend .font-weight-bold;
	    color:black;
	    background-color: white;
	}
    }
}

.papy-versand-tabelle {
    @extend .table;
    border-collapse: collapse !important;
    text-align: right;
    .odd {
	background-color: $papy-darken;
    }
    th, td {
	border: 1px solid black;
	padding: 4px !important;

    }
    th {
	text-align: left;
    }
    tr {
	&.papy-versand-tabelle-spacer {
	    td {
		border: none;
		padding: 0.5rem !important;
	    }
	}
	&.papy-versand-tabelle-land {
	    tr {
		background-color: white;
	    }
	    th {
		border: none;
		color: black;
		font-size: 120% !important;
	    }
	}
    }
}

.papy-versand-tabelle2 {
    @extend .table;
    border-collapse: collapse !important;
    text-align: right;
    th, td {
	border: 1px solid black !important;
	padding: 4px !important;
    }
    th {
	@extend .papy-bg-light;
    }
}


// Etiketten-Anfrage

.papy-label-form {
    max-width: 50em;
    @extend .mx-auto;
    .card-title {
	@extend .papy-bg-dark;
	padding-left: 8px;
    }
    .form-group.row, .form-group .row {
	padding-left: 8px;
    }
}

// Kontakt / Anfrage

.papy-kontakt {
    max-width: 50em;
    @extend .mx-auto;
}

form#anfrage {
    #bemerkung {
	width: 100%;
	height: 10em;
    }
    input[type=text], input[type=tel], input[type=email] {
	width: 100%;
    }
    input[name=hausnummer], input[name=plz], input[name=breite], input[name=hoehe], input[name=menge] {
	width: 8em;
    }
    input[name=farben] {
	width: 2em;
    }
    .container-fluid, .form-group.col {
	@extend .px-0;
    }
    .papy-col-1 {
	@extend .col-sm-3;
	max-width: 8em !important;
	padding-right: 0px;
    }
    .papy-col-2 {
	@extend .col-sm-9;
    }
    .papy-col-1a {
	@extend .col-sm-3;
	max-width: 11em !important;
	padding-right: 0px;
    }
    .papy-colgroup{
	.papy-col-1 {
	    @extend .col-sm-6;
	    max-width: 8em !important;
	    padding-right: 0px;
	}
	.papy-col-2 {
	    @extend .col-sm-6;
	}
    }
    label, legend {
	font-size: inherit;
	font-weight: bold;
    }
    .form-check-label {
	span {
	    margin-left: 4px !important;
	}
    }
}

.papy-checkbox-row {
    @extend .px-2;
    .form-check {
	@extend .ml-4;
    }
    label {
	font-weight: normal !important;
    }
}

// Sticky footer styles
//   http://getbootstrap.com/docs/4.0/examples/sticky-footer/sticky-footer.css
//--------------------------------------------------
html {
    position: relative;
    min-height: 100%;
}
body {
    margin-bottom: 40px; /* Margin bottom by footer height */
}
.footer {
    @extend .px-4;
    position: absolute;
    bottom: 0;
    width: 100%;
    height: 40px; /* Set the fixed height of the footer here */
    //  line-height: 30px; /* Vertically center the text there */
    //  background-color: #f5f5f5;
    max-width: $papy-body-max-width;
}
// End Sticky footer

.papy-logo {
    max-width: 100px;
    height:auto;
    @include media-breakpoint-up(sm) {
	max-width: 100%;
	height: auto;
    }
}

.papy-simulate-logo {
    font-weight: bold;
    font-size: 1.5rem;
    vertical-align: middle;
}

.papy-logo-hover-highlight {
    img { transform: scale(0.8); }
    img:hover { transform: scale(1); }
    &:focus { outline-style: none; }
}

.papy-badge-link-row {
    //@extend .d-flex;
    @extend .m-1;
    a {
	@extend .badge;
	@extend .badge-primary;
	@extend .p-2;
	@extend .m-1;
    }
    div {
	@extend .my-auto;
    }
}

.papy-link-light-underline {
    color: $papy-gray;
    text-decoration: underline;
}

.papy-intern {
    font-size: 90%;
    border: 1px solid red;
    background-color: $orange-4;
    color: black !important;
}
pre.papy-intern {
    font-size: 100% !important;
}
.papy-intern-link {
    @extend .papy-info-intern;
    @extend .fa-link;
}

.papy-scale {
    max-width: 100%;
    height: auto;
}

// Infoseiten

.papy-infopage {
    background:#ffffff !important;
    img {
	@extend .img-fluid;
    }
}

.papy-infopage-techtable {
    @extend .table;
    @extend .table-striped;
    @extend .table-sm;
    td:first-child  {
    @extend .w-25;
	font-weight:bold;
    }
}

.papy-infopage-2col {
    @extend .table;
    @extend .table-striped;
    @extend .table-sm;
    table {
	width:100%;
    }
    td:first-child  {
	@extend .w-25;
    }
    td {
    padding: 5px;
	@extend .text-left;
	@extend .align-text-top;
	@extend .hyphens-none;
	font-weight:bold;
	@include media-breakpoint-down(sm) {
	    font-size: 0.8rem !important;
	}
    }
    a {
	color: black;
	text-decoration: underline;
    }
}

.papy-infopage-cw-medien-table {
    @extend .table;
    @extend .table-borderless;
    @extend .table-responsive;
    @extend .table-sm;
    td {
	@extend .align-text-top;
	@extend .px-5;
	@extend .align-top;
    @include media-breakpoint-down(sm) {
	padding-left: 0px !important;
    }
  }
}

.papy-infopage-vm-table {
    @extend .table;
    @extend .table-borderless;
    @extend .table-sm;
	max-width: 100%;
	height: auto;
    th {
	@extend .justify-content-center;
	@extend .text-center;
	vertical-align: middle;
	font-weight: bold;
	max-width: 100%;
	height: auto;
  }
    td {
	@extend .justify-content-center;
	@extend .text-center;
	vertical-align: top;
	max-width: 100%;
	height: auto;
  }
}


.table-fixed{
    overflow:auto;
    width:100%;
    height:500px;
    @include media-breakpoint-up(md) {
	height:700px;
    }
    @extend .table-bordered;
    thead tr th {
	position:sticky;
	top:0;
    }
    td,th {
	@extend .mx-auto;
	@extend .text-center;
	align: top;
	width: 100px;
	background-color:white;
	border:1px solid black !important;
	font-size: 0.9rem;
	@include media-breakpoint-up(md) {
	    font-size: 1rem;
	}
    }
    div {
	@extend .text-left;
	@extend .d-inline-block;
    }
    table {
	table-layout: fixed;
	width:100%;
    }
    td:first-child, th:first-child {
	vertical-align: middle;
	width:100px;
	position:sticky;
	left:0;
	z-index:1;
	background-color:white;
    }

    td:first-child{
	@extend .text-right;
	font-weight: bold;
    }
    th:first-child{
	z-index:2;
	@extend .text-right;
    }
}


.table-fixed-450{
    overflow:auto;
    width:100%;
    height:600px;
    @include media-breakpoint-up(md) {
	height:600px;
    }
    @extend .table-bordered;
    thead tr th {
	position:sticky;
	top:0;
    }
    td,th {
	@extend .mx-auto;
	@extend .text-center;
	align: top;
	width: 30px;
	background-color:white;
	font-size: 0.9rem;
	@include media-breakpoint-up(md) {
	    font-size: 1rem;
	}
    }
    div {
	@extend .text-left;
	@extend .d-inline-block;
    }
    table {
	table-layout: fixed;
	width:100%;
    }
    td:first-child, th:first-child {
	vertical-align: middle;
	width:100px;
	position:sticky;
	left:0;
	z-index:1;
	background-color:white;
    }

    td:first-child{
	@extend .text-right;
	font-weight: bold;
	width: 100px;
    }
    th:first-child{
	z-index:2;
	@extend .text-right;
	width: 100px;
    }
}


.table-fixed-col3{
    @extend .table-bordered;
    div{
	overflow:auto;
	width:100%;
	height:500px;
	@include media-breakpoint-up(md) {
	height:700px;
	}
    }
    td,th {
	@extend .justify-content-center;
	@extend .text-center;
	vertical-align: middle;
	background-color:white;
	font-size: 0.9rem;
	@include media-breakpoint-up(md) {
	    font-size: 1rem;
	}
    }
    table {
	table-layout: fixed;
	width:100%;
    }
    td:first-child, th:first-child {
	@extend .text-right;
	position:sticky;
	left:0;
	z-index:1;
	background-color:white;
    }
    td:first-child{
	@extend .text-right;
	font-weight: bold;
	width: 20%;
    }
    thead tr th {
	position:sticky;
	top:0;
    }
    th:first-child{
	z-index:2;
    }
}


// m/drucker/fmarke_dymo_nachhaltigkeit
.table-fixed-no-first-child{
    overflow:auto;
    width:100%;
    height:500px;
    td,th {
	@extend .mx-auto;
	@extend .text-center;
	align: top;
	background-color:white;
	font-size: 0.9rem;
	@include media-breakpoint-up(md) {
	    font-size: 1rem;
	}
    }
    table {
	table-layout: fixed;
    }
}

.papy-infopage-table-scroll {
    @extend .table;
    @extend .table-striped;
    @extend .table-bordered;
    @extend .table-responsive-lg;
    @extend .table-sm;
	max-width: 100%;
	height: auto;
    th {
	@extend .justify-content-center;
	@extend .text-center;
	vertical-align: middle;
	font-weight: bold;
	max-width: 100%;
	height: auto;
    }
    thead{
	th:first-child{
	    position: absolute;
	}	
    }
    tbody{
	td:first-child{
	    position: absolute;
	}
    }
    td {
	@extend .justify-content-center;
	@extend .text-center;
	vertical-align: top;
	max-width: 100%;
	height: auto;
  }
    img {
	@extend .img-fluid;
  }
}

.papy-infopage-table-etik {
    @extend .table;
    @extend .table-bordered;
    @extend .table-sm;
    @extend .papy-bg-light;
    @extend .text-left;
	width: 100%;
	height: auto;	
	tr:first-child{
	    @extend .papy-bg-dark;
	    @extend .text-center;
	}	
	tr:nth-child(2){
	    @extend .papy-bg-dark;
	    @extend .text-center;
	}
	td {
	    @extend .justify-content-center;
	    @extend .font-weight-bold;
	    font-size: 0.9rem;
	    @include media-breakpoint-up(md) {
		font-size: 1rem;
	    }
	}
}

.papy-infopage-table-baender {
    @extend .table;
    @extend .table-bordered;
    @extend .table-sm;
    @extend .papy-bg-light;
    @extend .text-center;
	width: 100%;
	height: auto;	
	tr:first-child{
	    @extend .papy-bg-dark;
	}	
	tr:nth-child(2){
	    @extend .papy-bg-dark;
	}
	td {
	    @extend .justify-content-center;
	    @extend .text-center;
	    @extend .font-weight-bold;
	    font-size: 0.7rem;
	    @include media-breakpoint-up(sm) {
		font-size: 0.9rem;
	    }
	    @include media-breakpoint-up(md) {
		font-size: 1rem;
	    }
	}
}

.papy-infopage-table-ticklist {
    @extend .table;
    @extend .table-bordered;
    @extend .table-sm;
    @extend .mx-auto;
    position: relative;
    width: 100%;
    height: auto;
    table-layout: fixed;
    th {
	@extend .text-center;
	@extend .align-middle;
	@extend .papy-bg-light;
	position: sticky;
	top: 0;
	font-size: 0.7rem;
	@include media-breakpoint-up(sm) {
	    font-size: 0.9rem;
	}
	@include media-breakpoint-up(md) {
	    font-size: 1rem;
	}
    }
    th:first-child{
	@extend .w-25;
	figure{
	    @extend .figure;
	    img{
		@extend .img-fluid;
		@extend .figure-img;
	    }
	    a{
		font-size: 0.7rem;
		@include media-breakpoint-up(sm) {
		    font-size: 0.8rem;
		}
	    }
	    figcaption{
		font-size: 0.7rem;
		@include media-breakpoint-up(sm) {
		    font-size: 0.8rem;
		}
	    }
	}
    }
    td:first-child{
	@extend .width-100px;
	figure{
	    @extend .figure;
	    img{
		@extend .img-fluid;
		@extend .figure-img;
	    }
	    a{
		font-size: 0.7rem;
		@include media-breakpoint-up(sm) {
		    font-size: 0.8rem;
		}
	    }
	}
    }
    td {
	@extend .font-weight-bold;
	@extend .align-middle;
	@extend .text-center;
	width: 100%;
	height: auto;
	font-size: 1rem;
	@include media-breakpoint-up(sm) {
	    font-size: 1.25rem;
	}
	@include media-breakpoint-up(md) {
	    font-size: 1.5rem;
	}
    }
}

.papy-infopage-table-ticklist-m511 {
    @extend .table;
    @extend .table-bordered;
    @extend .table-sm;
    @extend .mx-auto;
    position: relative;
    width: 100%;
    height: auto;
    table-layout: fixed;
    th {
	@extend .text-center;
	@extend .align-middle;
	@extend .papy-bg-light;
	position: sticky;
	top: 0;
	font-size: 0.7rem;
	@include media-breakpoint-up(sm) {
	    font-size: 0.9rem;
	}
	@include media-breakpoint-up(md) {
	    font-size: 1rem;
	}
    }
    td:first-child{
	    a{
		font-size: 0.7rem;
		@include media-breakpoint-up(sm) {
		    font-size: 0.8rem;
		}
	    }
    }
    td {
	@extend .font-weight-bold;
	@extend .align-middle;
	@extend .text-center;
	width: 100%;
	height: auto;
    }
}

.table-xs {
  th,
  td {
    padding: 0px;
  }
}

.papy-infopage-table-d1 {
    @extend .table;
    @extend .table-bordered;
    @extend .table-sm;
    @extend .mx-auto;
    position: relative;
    width: 100%;
    height: auto;
    table-layout: fixed;
    th {
	@extend .text-center;
	@extend .align-top;
	@extend .papy-bg-light;
	position: sticky;
	top: 0;
	font-size: 0.6rem;
	@include media-breakpoint-up(sm) {
	    font-size: 0.9rem;
	}
	@include media-breakpoint-up(md) {
	    font-size: 1rem;
	}
    }
    td:first-child{
	max-width: 16% !important;
	figure{
	    @extend .figure;
	    img{
		@extend .img-fluid;
		@extend .figure-img;
	    }
	    a{
		font-size: 0.6rem;
		@include media-breakpoint-up(sm) {
		    font-size: 0.8rem;
		}
	    }
	}
    }
    td {
	max-width: 14%;
	@extend .align-middle;
	@extend .text-center;
	width: 100%;
	height: auto;
	font-size: 0.6rem;
	@include media-breakpoint-up(sm) {
	    font-size: 0.7rem;
	}
	@include media-breakpoint-up(md) {
	    font-size: 0.8rem;
	}
    }
}

.band {
    @extend .text-center;
    @extend .font-weight-bold;
    text-decoration: underline;
}

.papy-infopage-navbar {
    @extend .navbar;
    @extend .navbar-expand-md;
    @extend .bg-light;
//    @extend .sticky-top;
    padding: unset;
    padding-top: 1rem;
    margin-right: 0.2rem;
    ul {
	@extend .navbar-nav;
	/* @extend .nav-pills; */
	/* @extend .d-flex; */
	/* @extend .flex-wrap; */
    }
}


.papy-infopage-btn {
    @extend .btn;
    @extend .btn-sm;
    @extend .btn-outline-primary;
    @extend .mb-3;
    @extend .ml-1;
    @extend .mr-2;
    @include media-breakpoint-between(xs,sm){
	@include button-size($input-btn-padding-y-sm, $input-btn-padding-x-sm, $font-size-sm, $line-height-sm, $btn-border-radius-sm);
    }
}

.papy-infopage-btn-artikel {
    @extend .btn;
    @extend .btn-sm;
    @extend .btn-secondary;
    @extend .mb-3;
    @extend .ml-1;
    @extend .mr-2;
    @include media-breakpoint-between(xs,sm){
	@include button-size($input-btn-padding-y-sm, $input-btn-padding-x-sm, $font-size-sm, $line-height-sm, $btn-border-radius-sm);
    }
}

.papy-infopage-btn-primary {
    @extend .btn;
    @extend .btn-sm;
    @extend .btn-primary;
    @extend .mb-3;
    @extend .ml-1;
    @extend .mr-2;
    @include media-breakpoint-between(xs,sm){
	@include button-size($input-btn-padding-y-sm, $input-btn-padding-x-sm, $font-size-sm, $line-height-sm, $btn-border-radius-sm);
    }
}

.papy-infopage-ticklist-btn {
    @extend .btn;
    @extend .btn-outline-primary;
    @extend .mt-3;
    @include media-breakpoint-between(xs,sm){
	@include button-size($input-btn-padding-y-sm, $input-btn-padding-x-sm, $font-size-sm, $line-height-sm, $btn-border-radius-sm);
    }
}

.papy-btn-black-on-white {
    @extend .btn;
    @extend .btn-sm;
    @extend .btn-outline-dark;
    background-color:white;
    color: black !important;
    font-weight: bold;
    @extend .mb-3;
    @extend .ml-1;
    @extend .mr-2;
    @include media-breakpoint-between(xs,sm){
	@include button-size($input-btn-padding-y-sm, $input-btn-padding-x-sm, $font-size-sm, $line-height-sm, $btn-border-radius-sm);
    }
}

.papy-btn-black-on-yellow {
    @extend .btn;
    @extend .btn-sm;
    @extend .btn-outline-dark;
    @extend .papy-bg-yellow;
    color: black !important;
    font-weight: bold;
    @extend .mb-3;
    @extend .ml-1;
    @extend .mr-2;
    background-color:warning;
    @include media-breakpoint-between(xs,sm){
	@include button-size($input-btn-padding-y-sm, $input-btn-padding-x-sm, $font-size-sm, $line-height-sm, $btn-border-radius-sm);
    }
}

.papy-block-center-40proz {
    width: 40%;
    margin-left: 30%;
    display: block;
}

.papy-block-100proz {
    width: 100%;
    display: block;
}

.papy-infopage-dropdown {
	@extend .mb-3;
    button {
	@extend .papy-infopage-btn;
	@extend .dropdown-toggle;
	@extend .mb-3;
    }
    a {
	@extend .papy-infopage-btn;
    }
}

.papy-details {
    display: inline-block;
    summary {
      cursor: pointer;
      list-style: None;
//      background: url(/static/img/sortarrow_down.png) no-repeat 0px 50%;
      padding-left: 8px;
    }
    collapse {
      display:block;
      padding-left: 8px;
    }
}

.papy-details-orange {
    @extend .papy-details;
    border-left: $orange solid 4px;
}
.scroll-margin-top-1 {
    scroll-margin-top: 1rem;
}
.scroll-margin-top-2 {
    scroll-margin-top: 2rem;
}
.scroll-margin-top-3 {
    scroll-margin-top: 3rem;
}
.scroll-margin-top-4 {
    scroll-margin-top: 4rem;
}
section {
  scroll-margin-top: 8rem;
}
